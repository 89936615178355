import { PageTypeProps } from '@/types/site.interface'

export interface DataLayerUiProps {
  customerName?: string
  jobTitle?: string
}

export const getDataLayerUiProps = (data: PageTypeProps): DataLayerUiProps => {
  const { page, site } = data

  return {
    customerName: site?.title,
    jobTitle: page?.type === 'job' ? page.title : undefined,
  }
}
